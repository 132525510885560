* {
    box-sizing: border-box;
}

body {
    /* font-family: "Helvetica Neue",helvetica,arial,sans-serif; */
    background-color: #f9f9f9;
    /* font-family: 'Inconsolata', Monaco, "Lucida Console", Consolas, "Courier New"; */
    /* font-family: 'Lora', 'Merriweather', 'Playfair Display'; */
    font-family: "Red Hat Display", aktiv-grotesk, 'Roboto', 'Inconsolata', Monaco, Arial, Helvetica, sans-serif;

    /* font-size: 14px; */
    margin: 0;
}

a {
    color: #337ab7;
    text-decoration: none;
}

.container {
    margin: auto;
    width: 70vw;
    max-width: 650px;
}

#username-input {
    max-height: 30px;
    padding: 8px;
    margin: 0 6rem;
    border-radius: 3px;
    grid-column: 1 / 5;
}

#progress-tracking {
    margin: 15px auto;
    max-width: 28em;
}

#progress-tracking ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: column;
    /* position: absolute; */
}

#progress-tracking ul li {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 2px;
    /* position: absolute; */
}

img.checkmark,
img.error {
    height: 26px;
    width: 26px;
    margin: auto;
}

.grid-wrapper {
    display: grid;
    grid-template-rows: 50px 50px 50px 50px;
    grid-template-columns: 7em 6.4em 6.4em 7em;

    align-items: center;
    font-size: 16px;
}

label {
    font-weight: unset;
}

#results {
    max-height: 70vh;
    overflow-y: scroll;
}

@media only screen and (max-width: 700px) {
    .container {
        max-width: 95vw;
        width: unset;
    }

    .grid-wrapper {
        font-size: 13px;
        grid-template-columns: 6.5em 4.2em 4.2em 6.5em;
    }

    .movie-rec {
        font-size: 14px;
    }

    #recommendation-list {
        font-size: 14px;
        padding-left: 25px;
    }

    .predicted-rating-score {
        font-size: 16px;
    }

    .movie-rec {
        grid-template-columns: 18% 67% 15%;
    }

    .model-strength-label,
    .popularity-filter-label {
        font-size: 12px;
    }

    .data-opt-in-label {
        font-size: 12px;
    }

    #username-input {
        margin: 0 2rem;
    }
}

@media only screen and (max-width: 400px) {
    #recommendation-list {
        font-size: 13px;
        padding-left: 21px;
    }

    .movie-rec {
        font-size: 13px;
        grid-template-columns: 18% 63% 19%;
    }

    .predicted-rating-score {
        font-size: 15px;
    }

    .grid-wrapper {
        grid-template-columns: 6.4em 4em 4em 6.4em;
    }

    #recommendation-form {
        padding: 1.1em;
    }
}
