@import './settings.scss';

#intro-container {
    text-align: left;
    max-width: 650px;
    margin-top: 96px;

    .row {
        text-align: center;
        max-width: 580px;

        &.intro-paragraph {
            text-align: left;

            margin: 32px auto 24px;
            font-family: 'Yantramanav', 'aktiv-grotesk', Inconsolata, Monaco;

            p {
                font-size: 18px;
                line-height: 130%;
            }
        }

        &.byline {
            max-width: 580px;

            font-style: italic;
            text-align: left;

            margin: 0 auto 32px;
            font-size: 16px;
            line-height: 16px;

            .icon-img {
                height: 16px;
                width: auto;
                transform: translateY(5px);
            }
        }
    }

    h1 {
        font-size: 44px;
        line-height: 44px;
        font-family: 'Red Hat Display', 'aktiv-grotesk', Inconsolata, Monaco;
        font-weight: 500;
    }

    span.bolded {
        font-weight: 600;
    }
}

@media only screen and (max-width: 700px) {
    #intro-container {
        max-width: 85vw;
        margin-top: 64px;

        .row {
            &.intro-paragraph {
                margin: 32px auto 24px;

                width: 90%;
                max-width: 500px;

                p {
                    font-size: 16px;
                    line-height: 130%;
                }
            }

            &.byline {
                width: 90%;
                max-width: 500px;

                margin: 0 auto 24px;

                .icon-img {
                    height: 14px;
                    width: auto;
                    transform: translateY(4px);
                }
            }
        }

        h1 {
            font-size: 32px;
            line-height: 32px;
            font-family: 'Red Hat Display', 'aktiv-grotesk', Inconsolata, Monaco;
            font-weight: 500;
        }
    }
}
