.recommendation-form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: auto;

    padding: 2rem;
    /* background-color: #eee; */
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #444;
}

.recommendation-form .form-slider label {
    color: rgba(0,0,0,0.8) !important;
}

.data_opt_in_control label span {
    text-align: left;
}

.recommendation-form .submit-button button {
    font-size: 1rem;
}

.slider-end-label {
    width: 130px;
    font-size: 14px;
    text-align: left;
    line-height: 1.3;
}

#form-submit-button {
    margin-top: 10px;
    grid-column: 1 / 5;
    margin: 0 20%;
    padding: 5px 0;
    border-radius: 5px;
    /* max-width: 50%; */
}

.recommendation-form input {
    font-size: 1rem;
}

#body-content-container {
    min-height: 80vh;
}

.recommendation-form td {
    padding-right: 10px;
    padding-bottom: 5px;
}

#model-strength-slider,
#popularity-filter-slider {
    max-width: 90%;
    margin: auto;
    grid-column: 2 / 4;
}

.model-strength-label,
.popularity-filter-label {
    /* text-align: center; */
    font-size: 14px;
}

.model-strength-label--left {
    text-align: left;
}

.model-strength-label--right {
    text-align: right;
}

.loader {
    border: 3px solid #f3f3f3; /* Light gray */
    border-top: 3px solid #444; /* Dark gray */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin: auto;
    /* margin: 3px 3px 0px;	 */
}

.waiting-loader {
    border: 3px solid #f3f3f3; /* Light gray */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin: auto;
    /* opacity: 0.0; */
}

.progress-text {
    margin-left: 8px;
    text-align: left;
    font-size: 0.9rem;
    line-height: 1.3;
}

.progress-container {
    min-width: 30px;
    min-height: 30px;
    display: flex;
    align-items: center;
}

#download-container {
    text-align: center;
    min-height: 70px;
}

#download-button {
    max-width: 80%;
    margin: 15px auto 5px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #2196f3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(28px);
    -ms-transform: translateX(28px);
    transform: translateX(28px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

@media only screen and (max-width: 700px) {
    .recommendation-form {
        background-color: unset;
        border: unset;
        padding: 1.5rem;
    }

    .data_opt_in_control label {
        gap: 5px;
        color: rgba(0,0,0,0.8);
    }

    .slider-wrapper {
        padding-left: 0 !important;
    }

    .recommendation-form label, .recommendation-form span {
        font-size: 14px;
    }
}