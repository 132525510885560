
#genre-filter {
    max-height: 3rem;
    background-color: white;
}

#genre-filter .default-all-display {
    text-align: left;
    padding-left: 1rem;
}

.list-filter-controls {
    padding: 2rem 0;
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: #f3f3f3;
    overflow-x: hidden;
}